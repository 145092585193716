/* src/components/Navbar.css */

.navbar {
    background-color: #007bff;
    padding: 10px 0;
  }
  
  .navbar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .nav-item {
    margin-right: 20px;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  