/* AccountDeletion.css */

.page-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .page-title {
    color: var(--primary-color);
  }
  
  .page-icon {
    width: 100px; /* Adjust the size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .page-text {
    color: var(--text-color);
  }
  